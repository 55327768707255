<template>
	<div class="topic">
		<search-bar />

		<div class="title">
			<span>一起看！ 乐趣无限！</span>
			<div v-if="!hallMaintain">
				<img :src="createIcon" alt="" style="margin-right: 40px" @click="createShow = true" />
				<img :src="enterIcon" alt="" @click="getRandomRoom" />
			</div>
		</div>
		<div v-if="!hallMaintain">
			<div class="hall-box" v-loading="loading" element-loading-background="#121330">
				<div class="hall-list-item" v-for="(item, index) in topicList" @click="goTopicDetail(item)" :key="index">
					<!-- <img :src="getImg(item.movieInfo.mainPoster, 340, 490)" alt="" /> -->
					<lazy-pic :array="[item.movieInfo.mainPoster, 340, 490]" />
					<div class="hall-list-content">
						<div class="hall-list-content-title">
							<div class="content-title-avatar">
								<div class="content-title-avatar-item" v-if="item.userAvatar[0]">
									<img :src="getImg(item.userAvatar[0].avatar, 45, 45)" alt="" />
								</div>
								<div class="content-title-avatar-item second" v-if="item.userAvatar[1]">
									<img :src="getImg(item.userAvatar[1].avatar, 45, 45)" alt="" />
								</div>
							</div>
							<div class="contet-title-text">
								<div class="content-title-text-top">{{ item.name }}</div>
								<div class="content-title-text-bottom" v-if="item.userAvatar.length">
									{{ item.userAvatar[0].nickname + " " }}等{{ item.userAvatar.length }}人正在观看
								</div>
							</div>
						</div>
					</div>
				</div>
				<div :style="{ width: (4 - (topicList.length - parseInt(topicList.length / 4) * 4)) * 21 + '%' }"></div>
			</div>
			<div v-if="topicList.length === 0" style="margin-top:140px;height:500px;width: 100%;text-align: center;font-weight: 500;
			line-height: 21px;
			color: #797a8e;vertical-align: center">
				快来创建你的第一个影厅吧~
			</div>
		</div>
		<div v-if="hallMaintain">
			<div style="text-align: center;width: 100%;min-height: 250px">
				<img :src="warnImage" alt="" style="margin-top: 50px;width: 400px;height: 250px;margin: 110px auto auto auto">
				<div style="font-size: 15px;
font-family: PingFang SC;
font-weight: 500;
line-height: 21px;
color: #797A8E;">{{hallMaintainMsg}}</div>
			</div>
		</div>
		<!-- <page-select
      v-show="topicList.length"
      :pagination="pagination"
      @pageChange="pageChange"
    ></page-select> -->
		<div v-if="!hallMaintain" class="bodymovinanim" v-show="loadingShow">
			<div>
				<img :src="loadingIcon" alt="" />
			</div>
		</div>

		<el-dialog title="创建影厅" :visible.sync="createShow" width="30%">
			<div class="create-box">
				<img :src="createpIcon" alt="" />
				<div>请进入视频播放页面加入或创建影厅</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="createShow = false">知道了</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import searchBar from "./search-bar"
import pageSelect from "./page-select"
export default {
	name: "topic",
	data() {
		return {
			warnImage: require("@assets/img_maintain.png"),

			backgroundImage: require("@assets/index.png"),
			avatarImage: require("@assets/avatar.png"),
			wantIcon: require("@assets/explore/want.png"),
			timeIcon: require("@assets/aside/aside7.png"),
			emptyIcon: require("@assets/explore/empty.png"),
			selectIcon: require("@assets/recommond/select.png"),
			selectIcon2: require("@assets/recommond/select2.png"),
			noworkIcon: require("@assets/detail/empty.png"),
			createIcon: require("@assets/hall/create.png"),
			enterIcon: require("@assets/hall/enter.png"),
			createpIcon: require("@assets/hall/create-p.png"),

			loadingIcon: require("@assets/loading.gif"),
			loadingShow: false,
			createShow: false,
			searchWidth: 0,
			searchPlaceHolder: "复仇者联盟",
			searchVal: "",
			user: {
				name: "胖虎不是铁憨憨",
			},

			topicList: [],
			loading: true,
			pagination: {
				currentPage: 1,
				pageSize: 20,
				numFound: 0,
				totalPage: 0,
			},
			userSig: null,
			tencentUserId: null,
			// HALL_MAINTAIN
			hallMaintain: false,
			hallMaintainMsg: ''
		}
	},
	methods: {
		pageChange(e) {
			this.pagination.currentPage = e
			this.init()
			console.log(e)
		},
		async init() {
			this.loading = true
			const result = await this.api.getHallList({
				currentPage: this.pagination.currentPage,
				pageSize: this.pagination.pageSize,
			})
			if (result.code == 33) {
				this.hallMaintain = true
				this.hallMaintainMsg = result.msg
			} else {
				this.topicList = [...this.topicList, ...result.data.list]
				this.pagination = result.data.pagination
				this.tencentUserId = result.data.tencentUserId
				this.userSig = result.data.userSig
			}
			this.loading = false
		},
		goTopicDetail(item) {
			this.$router.push({
				name: "hall-detail",
				query: {
					id: item.videoId,
					hallId: item.hallId,
				},
			})
		},
		async getRandomRoom() {
			const result = await this.api.getRandomRoom()
			console.log(result)
			if (result.code === 1) {
				const item = result.data
				this.$router.push({
					name: "hall-detail",
					query: {
						id: item.videoId,
						hallId: item.hallId,
					},
				})
			} else {
				this.$message.error(result.msg)
			}
		},
		getScroll() {
			let _this = this
			document.querySelector(".el-main").addEventListener(
				"scroll",
				function(e) {
					// console.log(e)
					if (_this.pagination.totalPage !== _this.pagination.currentPage) {
						_this.loadingShow = true
					}
					const { scrollHeight, scrollTop, offsetHeight } = e.target
					// console.log(parseInt(scrollTop + offsetHeight), scrollHeight);
					if (parseInt(scrollTop + offsetHeight) === scrollHeight) {
						console.log("到底了")

						setTimeout(() => {
							console.log(_this.pagination)
							if (_this.pagination.totalPage === _this.pagination.currentPage) return
							_this.pagination.currentPage += 1
							_this.debounce(_this.pageChange(_this.pagination.currentPage), 1000)
						}, 500)
					}
				},
				true
			)
    },
     debounce(fn, delay) {
      let timer = null;
      return function () {
        if (timer) {
          clearInterval(timer);
          timer = setTimeout(fn, delay);
        } else {
          timer = setTimeout(fn, delay);
        }
      };
    },
	},
	watch: {},
	mounted() {
		this.init()
		this.getScroll()
	},
	components: {
		searchBar,
		pageSelect,
	},
}
</script>

<style lang="scss">
.topic {
	background: #121330;
	width: calc(100% - 100px);
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	padding: 0 50px;
	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		span {
			font-size: 30px;
			font-family: PingFang SC;
			font-weight: 600;
			line-height: 42px;
			color: #ffffff;
			user-select: none;
		}
		div {
			img {
				width: 164px;
				height: 72px;
				cursor: pointer;
			}
		}
	}
	.hall-box {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-top: 60px;
		cursor: pointer;
		.hall-list-item {
			width: 21%;

			margin-bottom: 50px;
			overflow: hidden;
			border-radius: 10px;
			position: relative;
			img {
				width: 100%;
				height: auto;
				display: block;
			}
			.hall-list-content {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 10;
				background: rgba(12, 12, 12, 0.6);
				top: 0;
				left: 0;
				.hall-list-content-title {
					margin-top: 30px;
					margin-left: 10px;

					.contet-title-text {
						margin-left: 80px;
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
						flex-direction: column;
						.content-title-text-top {
							font-size: 20px;
							font-family: PingFang SC;
							font-weight: 600;
							line-height: 42px;
							color: #ffffff;

							text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
						}
						.content-title-text-bottom {
							font-size: 12px;
							font-family: PingFang SC;
							font-weight: 400;
							line-height: 17px;
							color: #ffffff;

							text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
						}
					}
					.content-title-avatar {
						position: relative;
						position: relative;
						top: 8px;
						.content-title-avatar-item {
							width: 45px;
							height: 45px;
							overflow: hidden;
							border-radius: 50%;
							border: 1px solid #ffffff;
							background: white;
							z-index: 20;
							position: absolute;
							img {
								width: 100%;
								height: 100%;
							}
						}
						.second {
							top: 0;
							left: 23px;
							z-index: 10;
						}
					}
				}
			}
		}
	}
}
.create-box {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	img {
		width: 200px;
	}
	div {
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 22px;
		margin-top: 12px;
	}
}
@media screen and (max-width: 1500px) {
	.topic {
		.title {
			span {
				font-size: 24px;
			}
			div {
				img {
					width: 144px;
					height: 62px;
				}
			}
		}
		.hall-box {
			.hall-list-item {
				margin-bottom: 30px;
				.hall-list-content {
					.hall-list-content-title {
						margin-top: 20px;
						.contet-title-text {
							margin-left: 60px;
							.content-title-text-top {
								font-size: 18px;
							}
							.content-title-text-bottom {
								padding-right: 8px;
							}
						}
						.content-title-avatar {
							.content-title-avatar-item {
								width: 35px;
								height: 35px;
							}
							.second {
								left: 13px;
							}
						}
					}
				}
			}
		}
	}
}
</style>
